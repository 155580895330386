//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-324:_3180,_1296,_7840,_4696,_1008,_1312,_1724,_624;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-324')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-324', "_3180,_1296,_7840,_4696,_1008,_1312,_1724,_624");
        }
      }catch (ex) {
        console.error(ex);
      }